import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';
import axios from 'axios';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';


import {
  addCourse as onAddCourse,
  getCourseSchedulesMega as ongetCourseSchedulesMega,
  updateCourseSchedules as onUpdateCourseSchedules,
  deleteCourseSchedules as onDeleteCourseSchedules,

} from "store/contacts/actions";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs';


import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  Status
}
  from "./EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import moment from "moment";

function EcommerceOrder() {

  //meta title
  document.title = "Atölye Seansları | MEGA Admin Paneli";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [getWsID, setWsID] = useState(null);
  const [getWsText, setWsText] = useState(null);
  
  const [getWorkshopText, setWorkshopText] = useState(null);
  const [getWorksDay, setWorksDay] = useState(null);

  const [getFinishTime, setFinishTime] = useState(null);
  const [getStartTime, setStartTime] = useState(null);

  
  const [getWorkshopSessionData, setWorkshopSessionData] = useState([]);
  const [getWorkshopData, setWorkshopData] = useState([]);

  const [getWorkshopSessionEditList, setWorkshopSessionEditList] = useState();
  const [getWorkshopID, setWorkshopID] = useState();
  const [getIsActive, setIsActive] = useState(true);
  const [getDays, setDays] = useState(false);
  
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ID: (getWorkshopSessionEditList && getWorkshopSessionEditList.ID) || '',
      numberOfQutas: (getWorkshopSessionEditList && getWorkshopSessionEditList.Number_OfQuotas) || '',
      finishTime: (getWorkshopSessionEditList && getWorkshopSessionEditList.Course_Schedules_FinishTime) || '',
      startTime: (getWorkshopSessionEditList && getWorkshopSessionEditList.Course_Schedules_StartTime) || '',
      ageRange: (getWorkshopSessionEditList && getWorkshopSessionEditList.Age_Range) || '',
      numberOfQutasExtras: (getWorkshopSessionEditList && getWorkshopSessionEditList.Number_OfQuotas_Extra) || '',
    },
    validationSchema: Yup.object({
      ageRange: Yup.string().required("Lütfen Yaş Aralığını Giriniz"),
      numberOfQutas: Yup.string().required("Lütfen Kontenjan Giriniz")
    }),
    onSubmit: (values) => {
      if (isEdit) {
        console.log("sdnfkl",getStartTime)
        console.log("sdnfkl",getFinishTime)

        const updateOrder = {
          ID: order ? order.ID : 0,
       //   Course_Schedules_StartTime: getStartTime,
       //   Course_Schedules_FinishTime: getFinishTime,
          Number_OfQuotas_Extra:  values.numberOfQutasExtras,
          Number_OfQuotas: values.numberOfQutas,
          Age_Range: values.ageRange,
          Is_Active:getIsActive,
          Workshop_Session_ID:getWsID,
          Workshop_ID:getWorkshopID,
          Course_Schedules_Day : getDays
        }
        // update order
        dispatch(onUpdateCourseSchedules(updateOrder));
        validation.resetForm();
      } else {
        console.log("numberOfQutas123", values["numberOfQutas"])
        console.log("getIsActive",getIsActive)
        console.log("getWsID",getWsID)
        console.log("getWorkshopID",getWorkshopID)
        console.log("getDays",getDays)

        const newOrder = {
          // Course_Schedules_StartTime: getStartTime,
        //  Course_Schedules_FinishTime: getFinishTime,
          Is_Active:getIsActive,
          Number_OfQuotas: values["numberOfQutas"],
          Age_Range: values["ageRange"],
          Workshop_Session_ID:getWsID,
          Workshop_ID:getWorkshopID,
          Course_Schedules_Day : getDays
          
        }
        // save new order
        dispatch(onAddCourse(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.workshopSession,
  }));

  const _getWorkshopSession= async () => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getWorkshopSessionMega/select/`)
    .then((res) => {
      setWorkshopSessionData(res.data)
    })
  }

  const _getWorkshop= async () => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getWorkshopMega/select/`)
    .then((res) => {
      setWorkshopData(res.data)
    })
  }

  useEffect(() => {
    _getWorkshop()
    _getWorkshopSession()
    if (orders && !orders.length) {
      dispatch(ongetCourseSchedulesMega());
      setIsEdit(false);
    }
  }, [dispatch, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

    const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };
  
  const _setChoiceDays = arg => {
    setDays(arg.target.value)
   }

  const setChoiceWorkshop = arg => {
    setWorkshopID(arg.target.value)
   }
  const setChoice = arg => {
   setWsID(arg.target.value)
  }
  const handleOrderClick = arg => {
    const order = arg;
    setWorkshopSessionEditList(order)
    setOrder(order);
    setWsText(order.Workshop_Sessions.Workshop_Sessions_Text)
    setWorkshopText(order.Workshop.Workshop_Text)
    setWsID(order.Workshop_Session_ID)
    setWorkshopID(order.Workshop_ID)
    setDays(order.Course_Schedules_Day)

    setWorksDay(order.Course_Schedules_Day)
    setIsEdit(true);

    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.ID) {
      dispatch(onDeleteCourseSchedules(order.ID));
      setDeleteModal(false);
    }
  };



  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };
  

  const columns = useMemo(
    () => [

      {
        Header: 'Başvuru Dönemi',
        accessor: 'Workshop_Sessions.Workshop_Sessions_Text',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <OrderId {...cellProps} />;
        }
      },
      {
        Header: 'Atölye Adı',
        accessor: 'Workshop.Workshop_Text',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        }
      },
      {
        Header: 'Gün',
        accessor: 'Course_Schedules_Day',
        filterable: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      {
        Header: 'Yaş',
        accessor: 'Age_Range',
        filterable: true,
        disableFilters:true,
        Cell: (cellProps) => {
          return <Total {...cellProps} />;
        }
      },
      {
        Header: 'Kontenjan',
        accessor: 'Number_OfQuotas',
        filterable: true,
        disableFilters:true,
        Cell: (cellProps) => {
          return <PaymentStatus {...cellProps} />;
        }
      },
      {
        Header: 'Eks. Kontenjan',
        accessor: 'Number_OfQuotas_Extra',
        filterable: true,
        disableFilters:true,
        Cell: (cellProps) => {
          return <PaymentStatus {...cellProps} />;
        }
      },
      {
        Header: 'Durum',
        accessor: 'Is_Active',
        disableFilters:true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
      {
        Header: 'Detay',
        accessor: 'view',
        disableFilters: true,
        Cell: () => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={toggleViewModal}
            >
              Detay
            </Button>);
        }
      },
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="/mergeWorkshopSession"
                className="text-success"
               
              >
              Seansları Birleştir
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Yönetim" breadcrumbItem="Atölye Seansları" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    isWorkshopSession={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Seansı Düzenle" : "Seans Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                  
                    <div className="mb-3">
                      <Label className="form-label">Kontenjan</Label>
                      <Input
                        name="numberOfQutas"
                        type="number"
                        placeholder="Konjentan"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.numberOfQutas || ""}
                        invalid={
                          validation.touched.numberOfQutas && validation.errors.numberOfQutas ? true : false
                        }
                      />
                      {validation.touched.numberOfQutas && validation.errors.numberOfQutas ? (
                        <FormFeedback type="invalid">{validation.errors.numberOfQutas}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Ek Kontenjan</Label>
                      <Input
                        name="numberOfQutasExtras"
                        type="number"
                        placeholder="Konjentan"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.numberOfQutasExtras || ""}
                        invalid={
                          validation.touched.numberOfQutasExtras && validation.errors.numberOfQutasExtras ? true : false
                        }
                      />
                      {validation.touched.numberOfQutasExtras && validation.errors.numberOfQutasExtras ? (
                        <FormFeedback type="invalid">{validation.errors.numberOfQutasExtras}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['TimePicker']}>        
        <TimePicker
  label="Başlangıç Saati"
  value={dayjs(moment().format('YYYY-MM-DD')  + "T"+ validation.values.startTime)}
  onChange={(newValue) => setStartTime(newValue)}
/>


<TimePicker
  label="Bitiş Saati"
  value={dayjs(moment().format('YYYY-MM-DD')  + "T"+ validation.values.finishTime)}
  onChange={(newValue) => setFinishTime(newValue)}
/>


      </DemoContainer>
    </LocalizationProvider>

                    </div>
               
                  
                    <div className="mb-3">
                      <Label className="form-label">Yaş Aralığı</Label>
                      <Input
                        name="ageRange"
                        type="text"
                        placeholder="Yaş Aralığı"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ageRange || ""}
                        invalid={
                          validation.touched.ageRange && validation.errors.ageRange ? true : false
                        }
                      />
                      {validation.touched.ageRange && validation.errors.ageRange ? (
                        <FormFeedback type="invalid">{validation.errors.ageRange}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Başvuru Dönemi Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(text) => setChoice(text)}
                        value={
                          getWsText
                        }>
                        {getWorkshopSessionData.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Workshop_Sessions_Text}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>


                    <div className="mb-3">
                      <Label className="form-label">Gün Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(text) => _setChoiceDays(text)}
                        value={
                          getWorksDay
                        }>
                        
                           <option value={'pazartesi'} key={'pazartesi'}>Pazartesi</option>
                           <option value={'salı'} key={'salı'}>Salı</option>
                           <option value={'çarşamba'} key={'çarşamba'}>Çarşamba</option>
                           <option value={'perşembe'} key={'perşembe'}>Perşembe</option>
                           <option value={'cuma'} key={'cuma'}>Cuma</option>
                           <option value={'cumartesi'} key={'cumartesi'}>Cumartesi</option>
                           <option value={'pazar'} key={'pazar'}>Pazar</option>

                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>
                  
                    <div className="mb-3">
                      <Label className="form-label">Atölye Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(text) => setChoiceWorkshop(text)}
                        value={
                          getWorkshopText
                        }>
                        {getWorkshopData.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Workshop_Text}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>



                    <div className="d-flex">
                          <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                        
                       
                        </div>

                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;