import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormGroup,
  Alert
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import axios from 'axios';


const EcommerceAddProduct = () => {
  
  //meta title
  document.title = "Seans Birleştir | MEGA - Admin Paneli";


   
  const [getCourseSchedules, setCourseSchedules] = useState([]);

  const [getCourseSchedulesID, setCourseSchedulesID] = useState(null);

  const [getWsText, setWsText] = useState(null);

  const _getCourseSchedules = async () => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getCourseSchedulesMega/select/`)
    .then((res) => {
      setCourseSchedules(res.data)
    })
  }


  useEffect(() => {
    _getCourseSchedules()
  }, []);



  const options = [
    { value: "AK", label: "Alaska" },
    { value: "HI", label: "Hawaii" },
    { value: "CA", label: "California" },
    { value: "NV", label: "Nevada" },
    { value: "OR", label: "Oregon" },
    { value: "WA", label: "Washington" },
  ]
  

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Atölye Seansı" breadcrumbItem="Seans Birleştir" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Seans Birleştir</CardTitle>
                  <p className="card-title-desc mb-4">
                    Bütün Alanları Eksiksiz Doldurunuz!
                  </p>


                  <Alert color="primary">
                  Seansların dikkatlice seçilmesi gerekmektedir. Yeni seçilen alanları seçip seans güncelle dediğinizde ESKİ SEANS daki öğrenciler YENİ SEANS'a aktarılacak ve eski seans sistemden silinecektir.
                    </Alert>

                  <Form>
                    <Row>
       

                      <Col sm="8">
                       
                     
                      <div className="mb-3">
                      <Label className="form-label">Başvuru Dönemi Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(text) => setChoice(text)}
                        value={
                          getWsText
                        }>

                        {getCourseSchedules.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Workshop_Sessions.Workshop_Sessions_Text + " " + item.Workshop.Workshop_Text +  " " + item.Age_Range + " Yaş Aralığı " + item.Course_Schedules_Day + " " + item.Course_Schedules_StartTime + "-" + item.Course_Schedules_FinishTime}</option>
                        ))}
                       
                      </Input>
                    
                    </div>
                    
                        
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Birleştir
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>

        
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
