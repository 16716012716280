import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import  moment from "moment";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';

import {
  deleteOrder as onDeleteOrder,
} from "store/actions";

import {
  addNewWorkshop2 as onAdddNewWorkshop2,
  getWorkShop2 as onGetWorkShop2,
  updateWorkshop2 as onUpdateWorkshop2,
} from "store/contacts/actions";

import {
  Title,
  Date,
  Status,
}
  from "./EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";

function EcommerceOrder() {

  //meta title
  document.title = "ATÖLYELER | Mega - Admin Paneli";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [getWorkShopEdit, setWorkShopEdit] = useState(null);
 


  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ID: (getWorkShopEdit && getWorkShopEdit.ID) || '',

      startDate: (getWorkShopEdit && moment(getWorkShopEdit.Workshop_StartDate).format('YYYY-MM-DD')) || '',
      finishDate: (getWorkShopEdit && moment(getWorkShopEdit.Workshop_FinishDate).format('YYYY-MM-DD')) || '',
      title: (getWorkShopEdit && getWorkShopEdit.Workshop_Text) || '',

    },
    validationSchema: Yup.object({
      title: Yup.string().required("Başlığı Giriniz"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateWorkshop = {
          ID: order ? order.ID : 0,
          Workshop_Text: values.title,
          Workshop_IsActive:true
        //  Workshop_StartDate: values.orderdate,
        //   Workshop_FinishDate: values.startDate,
        };
        // update order
        dispatch(onUpdateWorkshop2(updateWorkshop));
        validation.resetForm();
      } else {
        const newOrder = {
          Workshop_Text: values["title"],
          Workshop_IsActive:true
        };
        // save new order
        dispatch(onAdddNewWorkshop2(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.workshop2,
  }));

  useEffect(() => {
    if (orders && !orders.length) {
      dispatch(onGetWorkShop2());
      setIsEdit(false);
    }
  }, [dispatch, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {
    const order = arg;
    setWorkShopEdit(order)
    setOrder(order);

    setIsEdit(true);
    
    toggle()
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      dispatch(onDeleteOrder(order.id));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Başlık',
        accessor: 'Workshop_Text',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        }
      },
   
   
      {
        Header: 'Baş.Bit. Tarihi',
        accessor: 'Workshop_StartDate',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      
  
      {
        Header: 'Aktiflik Durumu',
        accessor: 'Workshop_IsActive',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
    /* {
        Header: 'Detaylar',
        accessor: 'view',
        disableFilters: true,
        Cell: () => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={toggleViewModal}
            >
              Detayları Görüntüle
            </Button>);
        }
      },*/
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Subdomain" breadcrumbItem="ATÖLYELER" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    isAddWorkshop2={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Atölye Düzenle" : "Atölye Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">

                    <div className="mb-3">
                      <Label className="form-label">Başlangıç Zamanı</Label>
                      <Input
                        name="startdate"
                        type="date"
                        // value={orderList.orderdate || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.startDate || ""}
                        invalid={
                          validation.touched.startDate && validation.errors.startDate ? true : false
                        }
                      />
                      {validation.touched.startDate && validation.errors.startDate ? (
                        <FormFeedback type="invalid">{validation.errors.startDate}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Bitiş Zamanı</Label>
                      <Input
                        name="finishdate"
                        type="date"
                        // value={orderList.orderdate || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.finishDate || ""}
                        invalid={
                          validation.touched.finishDate && validation.errors.finishDate ? true : false
                        }
                      />
                      {validation.touched.finishDate && validation.errors.finishDate ? (
                        <FormFeedback type="invalid">{validation.errors.finishDate}</FormFeedback>
                      ) : null}
                    </div>
                  
                    <div className="mb-3">
                      <Label className="form-label">Başlık</Label>
                      <Input
                        name="title"
                        type="text"
                        placeholder="Başlık"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title || ""}
                        invalid={
                          validation.touched.title && validation.errors.title ? true : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                      ) : null}
                    </div>
                  
                  
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        KAYDET
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;