import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormGroup
} from "reactstrap";
import Select from "react-select";
import Dropzone from "react-dropzone";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EcommerceAddProduct = () => {
  
  //meta title
  document.title = "Add Product | Skote - React Admin & Dashboard Template";

  const [selectedFiles, setselectedFiles] = useState([])

  const [startDate, setstartDate] = useState(new Date());

  const startDateChange = date => {
    setstartDate(date);
  };

  const [step, setstep] = useState(0)

  const options = [
    { value: "AK", label: "Alaska" },
    { value: "HI", label: "Hawaii" },
    { value: "CA", label: "California" },
    { value: "NV", label: "Nevada" },
    { value: "OR", label: "Oregon" },
    { value: "WA", label: "Washington" },
  ]
  

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Add Product" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Seans Ekle</CardTitle>
                  <p className="card-title-desc mb-4">
                    Bütün Alanları Eksiksiz Doldurunuz!
                  </p>

                  <Form>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productname">Seans Adı</Label>
                          <Input
                            id="productname"
                            name="productname"
                            type="text"
                            className="form-control"
                            placeholder="Seans Adı"
                          />
                        </div>
                      
                        <div className="mb-3">

                        <Row>
                    <Col md={6}>
                      <div className="p-3">
                        <h5 className="font-size-14 mb-3 mt-0">Kontenjan</h5>
                        <span className="float-start mt-4">0</span>{" "}
                        <span className="float-end mt-4">100</span>
                        <Slider
                          value={step}
                          step={1}
                          orientation="horizontal"
                          onChange={value => {
                            setstep(value)
                          }}
                        />
                      </div>
                    </Col>

                
                  </Row>

                    
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="price">Yaş Aralığı</Label>
                          <Input
                            id="price"
                            name="price"
                            type="text"
                            className="form-control"
                            placeholder="Kontenjan"
                          />
                        </div>

                        <div
                          className="form-check form-switch form-switch-lg mb-3"
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizelg"
                            defaultChecked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customSwitchsizelg"
                          >
                            Aktif 
                          </label>
                        </div>

                      </Col>

                      <Col sm="6">
                       
                        <div className="mb-3">
                          <Label className="control-label">Başvuru Dönemi</Label>
                          <Select
                            classNamePrefix="select2-selection"
                            placeholder="Seçiniz..."
                            title="Kayıt Dönemi Dönemi"
                            options={options}
                            
                          />
                        </div>

                        <div className="mb-3">
                          <Label className="control-label">Atölye</Label>
                          <Select
                            classNamePrefix="select2-selection"
                            placeholder="Atölye seçiniz..."
                            title="Atölye Seçiniz"
                            options={options}
                            
                          />
                        </div>

                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                           Gün
                          </Label>
                          <Col lg="10">
                            <Row>
                              <Col md={6} className="pr-0">
                                <DatePicker
                                  className="form-control"
                                  selected={startDate}
                                  onChange={startDateChange}
                                />
                              </Col>
                             
                            </Row>
                          </Col>
                        </FormGroup>

                        
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" className="btn ">
                        Kaydet
                      </Button>
                      <Button type="submit" color="secondary" className=" ">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Product Images</CardTitle>
                  <Form>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                  </Form>
                </CardBody>
              </Card>


           
              <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="İçerik" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                 

                  <Form method="post">
                    <Editor
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                    />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="h4">İçerik</CardTitle>
                 

                  <Form method="post">
                    <CKEditor
                      editor={ClassicEditor}
                      data="<p>Hello from CKEditor 5!</p>"
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                      }}
                    />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

       

      </div>

      
    

              <Card>
                <CardBody>
              
                  <Form>
                    <Row>
                      <Col sm={6}>
                        <div className="mb-3">
                          <Label htmlFor="metatitle">Link</Label>
                          <Input
                            id="metatitle"
                            name="productname"
                            type="text"
                            className="form-control"
                            placeholder="Metatitle"
                          />
                        </div>
                   
                      </Col>

               
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" className="btn btn-primary waves-effect waves-light" color="primary">
                        Kaydet
                      </Button>
                      <Button type="submit" className="btn btn-secondary waves-effect waves-light" color="secondary">
                        İptal
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EcommerceAddProduct
