import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  GET_REFERANCES_SUCCESS,
  GET_WORKSHOP2_SUCCESS,
  GET_POPUP_SUCCESS,
  UPDATE_POPUP_SUCCESS,
  UPDATE_SUBDOMAIN_SUCCESS,
  UPDATE_SUCCESS_COURSESCHEDULES,
  UPDATE_WORKSHOPSESSION_SUCCESS,
  UPDATE_SUCCESS_TEACHER,
  UPDATE_SUCCESS_SLIDER,
  UPDATE_SUCCESS_NEWS,
  UPDATE_SUCCESS_MEGA,
  UPDATE_WORKSHOP2_SUCCESS,
  UPDATE_SUCCESS_GALLERY,

  GET_WORKSHOP_SUCCESS,
  GET_WORKSHOPSESSION_SUCCESS,
  GET_APPLICATION_SUCCESS,
  GET_SLIDER_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_NEWS_SUCCESS,
  GET_MEGA_SUCCESS,
  GET_TEACHER_SUCCESS,
  GET_DYNAMIC_SUCCESS,
  GET_CONTACT,
  GET_AUTH_SUCCESS,
  GET_AUTHUPDATE_SUCCESS,
  UPDATE_SUCCESS_PROJECT,
  GET_GALLERY_SUCCESS,
  ADD_REFERANCES_SUCCESS,
  UPDATE_REFERANCES_SUCCESS,
  GET_SUBPROJECT_SUCCESS,
  GET_TEACHERWORKSHOP_SUCCESS,
  ADD_LESSONSTIME_SUCCESS,
  UPDATE_LESSONSTIME_SUCCESS,
  GET_CLASSATTENDANCE_SUCCESS,

  GET_CLASSES_SUCCESS,
  UPDATE_DYNAMICFORM_SUCCESS,
  ADD_DYNAMICFORM_SUCCESS,
  GET_DYNAMICFORMEXTRA_SUCCESS,
  GET_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  ADD_MEGA_SUCCESS,
  DELETE_MEGA_SUCCESS,
  DELETE_NEWS_SUCCESS,
  DELETE_SLIDER_SUCCESS,
  DELETE_POPUP_SUCCESS,
  DELETE_REFERANCES_SUCCESS,
  ADD_GALLERY_SUCCESS,

  DELETE_GALLERY_SUCCESS,
  DELETE_COURSESCHEDULES_SUCCESS,
  DELETE_DYNAMICFORMEXTRA_SUCCESS,
  DELETE_DYNAMICFORM_SUCCESS,

  DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS,
  GET_EDUCATION_SUCCESS,
  GET_SUBEDUCATION_SUCCESS,
  UPDATE_EDUCATION_SUCCESS,
  ADD_EDUCATION_SUCCESS,
  ADD_SUBEDUCATION_SUCCESS,
  DELETE_SUBEDUCATION_SUCCESS,
  UPDATE_SUBEDUCATION_SUCCESS,
  GET_CLUE_SUCCESS,
  DELETE_CLUE_SUCCESS,
  UPDATE_CLUE_SUCCESS,
  ADD_CLUE_SUCCESS,
  GET_POLL_SUCCESS,
  ADD_EXPERTCATEGORY_SUCCESS,
  GET_EXPERTCATEGORY_SUCCESS,
  UPDATE_EXPERTCATEGORY_SUCCESS,
  DELETE_EXPERTCATEGORY_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  forum: [],
  users: [],
  userProfile: {},
  error: {},
  popup: [],
  workshop: [],
  workshopSession: [],
  workshopsessionMega: [],
  slider: [],
  projects: [],
  news:[],
  mega:[],
  teacher:[],
  dynamic:[],
  contact:[],
  authlist:[],
  workshop2:[],
  gallery:[],
  referances:[],
  subProject:[],
  lessonstime:[],
  classattendance:[],
  classes:[],
  
  dynamicformextra:[],
  dynamicformextraextra:[],
  education:[],
  educationSubItem:[],
  clue:[],
  poll:[],
  expertCategory:[],
}

const contacts = (state = INIT_STATE, action) => {
  switch (action.type) {

    case DELETE_EXPERTCATEGORY_SUCCESS:
      return {
        ...state,
        expertCategory: state.expertCategory.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }

    case GET_EXPERTCATEGORY_SUCCESS:
      return {
        ...state,
        expertCategory: action.payload,
      }

    case ADD_EXPERTCATEGORY_SUCCESS:
      return {
        ...state,
        expertCategory: [...state.expertCategory, action.payload],
      }

      case UPDATE_EXPERTCATEGORY_SUCCESS:
        return {
          ...state,
          expertCategory: state.expertCategory.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }

    case DELETE_CLUE_SUCCESS:
      return {
        ...state,
        clue: state.clue.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }

      case UPDATE_CLUE_SUCCESS:
        return {
          ...state,
          clue: state.clue.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }

        case ADD_CLUE_SUCCESS:
          return {
            ...state,
            clue: [...state.clue, action.payload],
          }

    case DELETE_DYNAMICFORMEXTRAEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextraextra: state.dynamicformextraextra.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_DYNAMICFORM_SUCCESS:
      return {
        ...state,
        dynamic: state.dynamic.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }
    
    case DELETE_DYNAMICFORMEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextra: state.dynamicformextra.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

      
      case UPDATE_EDUCATION_SUCCESS:
        return {
          ...state,
          education: state.education.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }


              case UPDATE_USER_SUCCESS:
        return {
          ...state,
          forum: state.forum.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }

        case UPDATE_DYNAMICFORM_SUCCESS:
          return {
            ...state,
            dynamic: state.dynamic.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }

          
          case DELETE_USER_SUCCESS:
            return {
              ...state,
              forum: state.forum.filter(
                forum => forum.ID.toString() !== action.ID.toString()
              ),
            }

    case DELETE_COURSESCHEDULES_SUCCESS:
      return {
        ...state,
        workshopSession: state.workshopSession.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: state.gallery.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

      case UPDATE_SUBEDUCATION_SUCCESS:
        return {
          ...state,
          educationSubItem: state.dynamic.map(x =>
            x.ID == action.educationSubItem.ID
              ? { x, ...action.payload }
              : x
          ),
        }


    case DELETE_SUBEDUCATION_SUCCESS:
      return {
        ...state,
        educationSubItem: state.educationSubItem.filter(
          user => user.ID.toString() !== action.payload.toString()
        ),
      }

      case ADD_SUBEDUCATION_SUCCESS:
        return {
          ...state,
          educationSubItem: [...state.educationSubItem, action.payload],
        }

      case ADD_EDUCATION_SUCCESS:
        return {
          ...state,
          education: [...state.education, action.payload],
        }

    case ADD_GALLERY_SUCCESS:
      return {
        ...state,
        gallery: [...state.gallery, action.payload],
      }

    case DELETE_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: state.referances.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_POPUP_SUCCESS:
      return {
        ...state,
        popup: state.popup.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_SLIDER_SUCCESS:
      return {
        ...state,
        slider: state.slider.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_NEWS_SUCCESS:
      return {
        ...state,
        news: state.news.filter(
          user => user.ID.toString() !== action.ID.toString()
        ),
      }

    case DELETE_MEGA_SUCCESS:
        return {
          ...state,
          mega: state.mega.filter(
            user => user.ID.toString() !== action.ID.toString()
          ),
        }
  

    case ADD_MEGA_SUCCESS:
      return {
        ...state,
        mega: [...state.mega, action.payload],
      }

                  
      case GET_POLL_SUCCESS:
        return {
          ...state,
          poll: action.payload,
        }

            
      case GET_CLUE_SUCCESS:
        return {
          ...state,
          clue: action.payload,
        }


      case GET_SUBEDUCATION_SUCCESS:
        return {
          ...state,
          educationSubItem: action.payload,
        }

      case GET_EDUCATION_SUCCESS:
        return {
          ...state,
          education: action.payload,
        }

    case GET_DYNAMICFORMEXTRAEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextraextra: action.payload,
      }

    case GET_DYNAMICFORMEXTRA_SUCCESS:
      return {
        ...state,
        dynamicformextra: action.payload,
      }

    case UPDATE_DYNAMICFORM_SUCCESS:
      return {
        ...state,
        dynamic: state.dynamic.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case GET_CLASSES_SUCCESS:
      return {
        ...state,
        classes: action.payload,
      }

    case GET_CLASSATTENDANCE_SUCCESS:
      return {
        ...state,
        classattendance: action.payload,
      }

    case UPDATE_LESSONSTIME_SUCCESS:
      return {
        ...state,
        lessonstime: state.lessonstime.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }
      
    case ADD_DYNAMICFORM_SUCCESS:
        return {
          ...state,
          dynamic: [...state.dynamic, action.payload],
        }

    case ADD_LESSONSTIME_SUCCESS:
      return {
        ...state,
        lessonstime: [...state.lessonstime, action.payload],
      }

      case GET_TEACHERWORKSHOP_SUCCESS:
        return {
          ...state,
          lessonstime: action.payload,
        }

        
    case GET_SUBPROJECT_SUCCESS:
      return {
        ...state,
        subProject: action.payload,
      }

    case UPDATE_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: state.referances.map(x =>
          x.ID == action.payload.ID
            ? { x, ...action.payload }
            : x
        ),
      }

    case GET_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: action.payload,
      }
         
    case GET_AUTHUPDATE_SUCCESS:
      return {
        ...state,
        authlist: [],
      }

    case GET_AUTH_SUCCESS:
      return {
        ...state,
        authlist: action.payload,
      }

      
      case GET_WORKSHOP2_SUCCESS:
        return {
          ...state,
          workshop2: action.payload,
        }

    case GET_CONTACT:
      return {
        ...state,
        contact: action.payload,
      }

    case GET_DYNAMIC_SUCCESS:
      return {
        ...state,
        dynamic: action.payload,
      }

    case GET_TEACHER_SUCCESS:
      return {
        ...state,
        teacher: action.payload,
      }
    case GET_MEGA_SUCCESS:
      return {
        ...state,
        mega: action.payload,
      }

    case GET_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload,
      }

    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      }

    case GET_SLIDER_SUCCESS:
      return {
        ...state,
        slider: action.payload,
      }
    case GET_APPLICATION_SUCCESS:
      return {
        ...state,
        workshopsessionMega: action.payload,
      }
    case GET_WORKSHOPSESSION_SUCCESS:
      return {
        ...state,
        workshopSession: action.payload,
      }
    case GET_WORKSHOP_SUCCESS:
      return {
        ...state,
        workshop: action.payload,
      }

      case GET_GALLERY_SUCCESS:
        return {
          ...state,
          gallery: action.payload,
        }

        
        case UPDATE_SUCCESS_GALLERY:
          console.log("deneme123",action.payload)
          return {
            ...state,
            gallery: state.gallery.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }
      
          
      case UPDATE_SUCCESS_MEGA:
        console.log("deneme123",action.payload)
        return {
          ...state,
          mega: state.mega.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }
      
        
                
      case UPDATE_WORKSHOP2_SUCCESS:
        return {
          ...state,
          workshop2: state.workshop2.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

      case UPDATE_SUCCESS_NEWS:
        return {
          ...state,
          news: state.news.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }


      case UPDATE_SUCCESS_PROJECT:
        return {
          ...state,
          projects: state.projects.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

       case UPDATE_SUCCESS_SLIDER:
        return {
          ...state,
          slider: state.slider.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

      case UPDATE_SUBDOMAIN_SUCCESS:
        return {
          ...state,
          workshop: state.workshop.map(x =>
            x.ID == action.payload.ID
              ? { x, ...action.payload }
              : x
          ),
        }

        
        case UPDATE_SUCCESS_TEACHER:
          return {
            ...state,
            teacher: state.teacher.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }


        case UPDATE_WORKSHOPSESSION_SUCCESS:
          return {
            ...state,
            workshopsessionMega: state.workshopsessionMega.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }

        case UPDATE_SUCCESS_COURSESCHEDULES:
          console.log("lksdnfsd",state.workshopSession)
          return {
            ...state,
            workshopSession: state.workshopSession.map(x =>
              x.ID == action.payload.ID
                ? { x, ...action.payload }
                : x
            ),
          }

     case UPDATE_POPUP_SUCCESS:
        return {
          ...state,
          popup:  state.popup.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }
        
    case GET_POPUP_SUCCESS:
      return {
        ...state,
        popup:  action.payload,
      }

    case GET_USERS_SUCCESS:
      return {
        ...state,
        forum: action.payload,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    
      case ADD_REFERANCES_SUCCESS:
      return {
        ...state,
        referances: [...state.referances, action.payload],
      }


    case ADD_USER_SUCCESS:

      return {
        ...state,
        forum: [...state.forum, action.payload],
      }

      case UPDATE_USER_SUCCESS:
        return {
          ...state,
          forum: state.forum.map(x =>
            x.ID.toString() === action.payload.ID.toString()
              ? { x, ...action.payload }
              : x
          ),
        }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.payload,
      }


  
      case UPDATE_USER_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  

  
      case DELETE_USER_FAIL:
        return {
          ...state,
          error: action.payload,
        }

    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default contacts
