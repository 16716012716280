import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {
  deleteOrder as onDeleteOrder,
} from "store/actions";

import {
  addProject as onAddProject,
  updateProject as onUpdateProject,
  getProjectsNew as onGetProjects,
} from "store/contacts/actions";

import {
  Name,
  Status
}
  from "./EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";
import {Base64} from 'js-base64';

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';
import { Buffer } from "buffer";

function EcommerceOrder() {

  //meta title
  document.title = "Projeler | Mega - Admin Paneli";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [getHtmlData, setHtmlData] = useState(null);

  
  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [getEditProject, setEditProject] = useState(null);
  const [getIsActive, setIsActive] = useState(false);
  const [getFileName, setFileName] = useState(null);
  const [getFileNameTopBanner, setFileNameTopBanner] = useState(null);
  const [getFileNameBottomBanner, setFileNameBottomBanner] = useState(null);

  const [getProjectPicture, setProjectPicture] = useState(null);
  const [getTopBannerImage, setTopBannerImage] = useState(null);
  const [getBottomBannerImage, setBottomBannerImage] = useState(null);

  
  
  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }
   
  const handleChangeStatus = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileName(result.file.filename)

        }
      }
    }
  }

  const handleChangeStatusTopBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileNameTopBanner(result.file.filename)

        }
      }
    }
  }

  const handleChangeStatusBottomBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response);
          setFileNameBottomBanner(result.file.filename)

        }
      }
    }
  }
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (getEditProject && getEditProject.Project_Title) || '',
      projectDesc: (getEditProject && getEditProject.Project_Desc) || '',
      bottomBannerText: (getEditProject && getEditProject.Bottom_BannerText) || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Başlık Giriniz"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          ID: order ? order.ID : 0,
          Project_Title: values.name,
          Project_Desc: Base64.encode(getHtmlData),
          Project_TopBannerImage: getFileNameTopBanner,
          Project_Picture: getFileName,
          Bottom_BannerImage: getFileNameBottomBanner,
          Bottom_BannerText: values.bottomBannerText,
          Project_IsActive: getIsActive
        }
        // update order
        dispatch(onUpdateProject(updateOrder));
        dispatch(onGetProjects());
        validation.resetForm();
      } else {
        const newOrder = {
          Project_Title: values["name"],
       //   Project_Desc: getHtmlData,
         // Project_TopBannerImage: values.Project_TopBannerImage,
         // Project_Picture: values.Project_Picture,
        //  Bottom_BannerImage: values.Bottom_BannerImage,
         // Bottom_BannerText: values.Bottom_BannerText,
          Project_IsActive: getIsActive
        };
        // save new order
        dispatch(onAddProject(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.projects,
  }));

  useEffect(() => {
   if (orders && !orders.length) {
      dispatch(onGetProjects());
      setIsEdit(false);
  }
  }, [dispatch, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {
    const order = arg;
    setEditProject(order)
    setOrder(order);
    
    setFileName(order.Project_Picture)

    setFileNameTopBanner(order.Project_TopBannerImage)
    setHtmlData(Base64.decode(order.Project_Desc))

    setProjectPicture(order.Project_Picture)
    setBottomBannerImage(order.Bottom_BannerImage)
    setTopBannerImage(order.Project_TopBannerImage)

    setIsEdit(true);

    toggle();
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      dispatch(onDeleteOrder(order.id));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Proje Adı",
        accessor: "Project_Title",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Proje Görseli",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.Project_Picture ? (
              <div className="avatar-xl">
             { cellProps.Project_Title != null ?  <span className="avatar-title rounded-circle">
                  {cellProps.Project_Title.charAt(0)}
                </span> : null}
              </div>
            ) : (
              <div>
                <img
                  className="avatar-xl"
                  src={'http://megasubdomain.stechomeyazilim.info/' + cellProps.Project_Picture}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
      {
        Header: "Üst Banner Görseli",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {!cellProps.Project_TopBannerImage ? (
              <div className="avatar-xl">
               { cellProps.Project_Title != null ? <span className="avatar-title rounded-circle">
                  {cellProps.Project_Title.charAt(0)}
                </span> : null }
              </div>
            ) : (
              <div>
                <img
                  className="avatar-xl"
                  src={'http://megasubdomain.stechomeyazilim.info/' + cellProps.Project_TopBannerImage}
                  alt=""
                />
              </div>
            )}
          </>
        ),
      },
    
      {
        Header: 'Aktiflik Durumu',
        accessor: 'Project_IsActive',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        }
      },
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
                  <Link
                to="/subProject"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
             
                  Alt Metin Ekle
               
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Projeler" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    isProject={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Proje Düzenle" : "Proje Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    
                
                    <div className="mb-3">
                      <Label className="form-label">Başlık</Label>
                      <Input
                        name="name"
                        type="text"
                        placeholder="Başlık"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                          validation.touched.name && validation.errors.name ? true : false
                        }
                      />
                      {validation.touched.name && validation.errors.name ? (
                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Alt Banner Başlık</Label>
                      <Input
                        name="name"
                        type="text"
                        placeholder="Alt Banner Başlık"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.bottomBannerText || ""}
                        invalid={
                          validation.touched.bottomBannerText && validation.errors.bottomBannerText ? true : false
                        }
                      />
                      {validation.touched.bottomBannerText && validation.errors.bottomBannerText ? (
                        <FormFeedback type="invalid">{validation.errors.bottomBannerText}</FormFeedback>
                      ) : null}
                    </div>
                    

                    <div className="mb-3">
                    <Label className="form-label">Proje Detay</Label>
                    <Form method="post">
                    <CKEditor
                      editor={ClassicEditor}
                      data={getHtmlData}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setHtmlData(data)
                      }}
                    />
                  </Form>
                  </div>


                  <Row>
                <Col xs={12} className="mb-3">
                            <label htmlFor="resume">Proje Görseli(800*600)</label>
                         <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatus}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          canRemove={false}
          PreviewComponent={Preview}
          accept="image/*"
        />
                
                          </Col>
                </Row>
                
                <Row>
                <label htmlFor="resume"> Mevcut Eklenen Resim</label>

                <Col xs={12} className="mb-3">
                            <img
                  className=" avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' +  getProjectPicture}
                  alt=""
                />
                          </Col>
                </Row>

                <Row>
                <Col xs={12} className="mb-3">
                            <label htmlFor="resume">Üst Banner Görseli(800*600)</label>
                         <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatusTopBanner}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          canRemove={false}
          PreviewComponent={Preview}
          accept="image/*"
        />
                
                          </Col>
                </Row>

                <Row>
                <label htmlFor="resume"> Mevcut Eklenen Resim</label>

                <Col xs={12} className="mb-3">
                            <img
                  className=" avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' +  getTopBannerImage}
                  alt=""
                />
                          </Col>
                </Row>

                <Row>
                <Col xs={12} className="mb-3">
                            <label htmlFor="resume">Alt Banner Görseli(800*600)</label>
                         <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={handleChangeStatusBottomBanner}
          styles={{
            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
            inputLabelWithFiles: { margin: '20px 3%' }
          }}
          canRemove={false}
          PreviewComponent={Preview}
          accept="image/*"
        />
                
                          </Col>
                </Row>

                <Row>
                <label htmlFor="resume"> Mevcut Eklenen Resim</label>

                <Col xs={12} className="mb-3">
                            <img
                  className=" avatar-xl"
                  src={'https://megasubdomain.stechomeyazilim.info/' +  getBottomBannerImage}
                  alt=""
                />
                          </Col>
                </Row>

                  <div className="d-flex">
                          <div className="square-switch">
                            <input
                              type="checkbox"
                              id="square-switch1"
                              className="switch"
                              defaultChecked={getIsActive}
                              onChange={() =>
                                setIsActive(!getIsActive)
                              }
                            />
                            <label
                         htmlFor="square-switch1"
                              data-on-label="Aktif "
                              data-off-label="Pasif"
                            />
                          </div>
                        
                       
                        </div>
                        
                
               
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Kaydet
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;