import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from "lodash";
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from '../../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";

//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import DeleteModal from '../../../components/Common/DeleteModal';
import axios from 'axios';

import {
  deleteOrder as onDeleteOrder,
} from "store/actions";

import {
  addLessonsTime as onAddLessonsTime,
  getTeacherWorkshopMega as onGetTeacherWorkshopMega,
  updateLessonsTime as onUpdateLessonsTime,
} from "store/contacts/actions";

import {
  Title,
  Date,
  Status,
}
  from "./EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap";

function EcommerceOrder() {

  //meta title
  document.title = "DERS PROGRAMI | Mega - Admin Paneli";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [getWorkShopEdit, setWorkShopEdit] = useState(null);
 
  const [getCourseSchedules, setCourseSchedules] = useState([]);

  const [getCourseSchedulesID, setCourseSchedulesID] = useState(null);
  const [getWsText, setWsText] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      ID: (getWorkShopEdit && getWorkShopEdit.ID) || '',

    
      title: (getWorkShopEdit && getWorkShopEdit.Teacher_Workshop_Title) || '',
      tools: (getWorkShopEdit && getWorkShopEdit.Teacher_Workshop_Tools) || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Başlığı Giriniz"),
      tools: Yup.string().required("Araç Gereçleri Giriniz"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateWorkshop = {
          ID: order ? order.ID : 0,
          Teacher_Workshop_Title: values.title,
          Teacher_Workshop_Tools: values.tools,
          Course_Schedules_ID:getCourseSchedulesID
        };
        // update order
        dispatch(onUpdateLessonsTime(updateWorkshop));
        validation.resetForm();
      } else {
        const newOrder = {
          Is_Active:true,
          Course_Schedules_ID:getCourseSchedulesID,
          Teacher_Workshop_Title:values["title"],
          Teacher_Workshop_Tools:values["tools"],
        };
        // save new order
        dispatch(onAddLessonsTime(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);

  const dispatch = useDispatch();
  const { orders } = useSelector(state => ({
    orders: state.contacts.lessonstime,
  }));

  const _getCourseSchedules = async () => {
  
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getCourseSchedulesMega/select/`)
    .then((res) => {
      setCourseSchedules(res.data)
    })
  }

  useEffect(() => {
    _getCourseSchedules()
  }, []);

  useEffect(() => {
    if (orders && !orders.length) {
      dispatch(onGetTeacherWorkshopMega());
      setIsEdit(false);
    }
  }, [dispatch, orders]);

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders) && !!isEdit) {
      setOrderList(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = arg => {
    const order = arg;
    setWorkShopEdit(order)
    setOrder(order);

    setIsEdit(true);
    setWsText(order.Workshop_Sessions.Workshop_Sessions_Text + " " + order.Workshop.Workshop_Text)

    toggle()
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      dispatch(onDeleteOrder(order.id));
      setDeleteModal(false);
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const setChoice = arg => {
    setCourseSchedulesID(arg.target.value)
   }

  const columns = useMemo(
    () => [
      {
        Header: 'Atölye Adı',
        accessor: 'Course_Schedules.Workshop_Sessions.Workshop_Sessions_Text',
        disableFilters: true,
        filterable: true,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        }
      },
   
   
      {
        Header: 'Grup Adı',
        accessor: 'Course_Schedules.Workshop.Workshop_Text',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        }
      },
      
  
      {
        Header: 'Etkinlik Adı',
        accessor: 'Teacher_Workshop_Title',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        }
      },

      {
        Header: 'Araç Gereçler',
        accessor: 'Teacher_Workshop_Tools',
        filterable: true,
        disableFilters: true,
        Cell: (cellProps) => {
          return <Title {...cellProps} />;
        }
      },
    /* {
        Header: 'Detaylar',
        accessor: 'view',
        disableFilters: true,
        Cell: () => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={toggleViewModal}
            >
              Detayları Görüntüle
            </Button>);
        }
      },*/
      {
        Header: 'İşlemler',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Yönetim" breadcrumbItem="DERS PROGRAMI" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={orders}
                    isGlobalFilter={true}
                    isAddLessonsTime={true}
                    handleOrderClicks={handleOrderClicks}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Ders Programı Düzenle" : "Ders Programı Ekle"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">


                  <div className="mb-3">
                      <Label className="form-label">Başvuru Dönemi Seçiniz</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(text) => setChoice(text)}
                        value={
                          getWsText
                        }>

                        {getCourseSchedules.map((item, index) => (
                           <option value={item.ID} key={item.ID}>{item.Workshop_Sessions.Workshop_Sessions_Text + " " + item.Workshop.Workshop_Text}</option>
                        ))}
                       
                      </Input>
                      {validation.touched.wsText && validation.errors.wsText ? (
                        <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Etkinlik Adı</Label>
                      <Input
                        name="title"
                        type="text"
                        placeholder="Etkinlik Adı"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.title || ""}
                        invalid={
                          validation.touched.title && validation.errors.title ? true : false
                        }
                      />
                      {validation.touched.title && validation.errors.title ? (
                        <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Araç Gereçler</Label>
                      <Input
                        name="tools"
                        type="text"
                        placeholder="Araç Gereçler"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.tools || ""}
                        invalid={
                          validation.touched.tools && validation.errors.tools ? true : false
                        }
                      />
                      {validation.touched.tools && validation.errors.tools ? (
                        <FormFeedback type="invalid">{validation.errors.tools}</FormFeedback>
                      ) : null}
                    </div>
                  
                  
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        KAYDET
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}
EcommerceOrder.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default EcommerceOrder;